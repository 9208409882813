/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import React from "react";
import { WpMediaItem, Query } from "../../gatsby-types";
import Seo from "gatsby-plugin-wpgraphql-seo";

import Hero from "../components/Hero";
import Layout from "../components/Layout";
import RetreatsSection from "../components/RetreatsSection";
import SectionGrey from "../components/SectionGrey";

type PropsType = {
  data: Query;
};

type acfRetreatsProps = {
  hero: string;
  heroSubheader: string;
  contentHeader: string;
  contentText: string;
  buttonLabel: string;
  imageMobile: WpMediaItem;
  imageTabletUp: WpMediaItem;
};

const Retreats: React.FC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfRetreats) {
    return null;
  }

  const { hero, heroSubheader, contentHeader, contentText, buttonLabel, imageMobile, imageTabletUp } = props.data.wpPage
    .acfRetreats as acfRetreatsProps;

  return (
    <Layout>
      <Seo post={props.data.wpPage} />
      <Hero page="retreats" header={hero} paragraph={heroSubheader} />
      <SectionGrey>
        <RetreatsSection
          buttonLabel={buttonLabel}
          title={contentHeader}
          content={contentText}
          imageMobile={imageMobile.localFile}
          imageTabletUp={imageTabletUp.localFile}
        />
      </SectionGrey>
    </Layout>
  );
};

export const pageQuery = graphql`
  query RetreatsPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfRetreats {
        hero
        heroSubheader
        contentHeader
        contentText
        buttonLabel
        imageMobile {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 670, height: 434)
            }
          }
        }
        imageTabletUp {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1110, height: 1076)
            }
          }
        }
      }
    }
  }
`;

export default Retreats;
