import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { File, Maybe } from "../../../gatsby-types";

import Button from "../Button";
import ButtonAnchor from "../Button/ButtonAnchor";

import { media, Breakpoint, themeColor } from "../../theme";

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 335px;
  height: 217px;
  margin: 0 auto;

  ${media(Breakpoint.Tablet)} {
    max-width: 555px;
    height: 538px;
    order: 2;
  }

  > div {
    &:first-of-type {
      height: 100%;

      ${media(Breakpoint.Tablet)} {
        display: none;
      }
    }

    &:nth-of-type(2) {
      display: none;

      ${media(Breakpoint.Tablet)} {
        display: block;
        height: 100%;
      }
    }
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  max-width: 335px;
  margin: 0 auto;

  ${media(Breakpoint.Tablet)} {
    max-width: none;
  }

  p {
    max-width: 670px;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    color: ${themeColor("black")};
    margin: 0 0 20px;

    &:last-of-type {
      margin: 0;
    }
  }

  ${ButtonAnchor} {
    width: 100%;
    max-width: 325px;
    display: block;
    margin: 20px auto 0;

    ${media(Breakpoint.Tablet)} {
      margin: 20px 0 0;
    }

    ${Button} {
      width: 100%;
    }
  }
`;

const Title = styled.h3`
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("grey700")};
  margin: 0 0 20px;

  ${media(Breakpoint.Mobile)} {
    font-size: 36px;
    line-height: 48px;
  }
`;

interface RetreatsSectionProps {
  className?: string;
  title: string;
  content: string;
  buttonLabel: string;
  imageMobile?: Maybe<File>;
  imageTabletUp?: Maybe<File>;
}

const RetreatsSection: React.FC<RetreatsSectionProps> = ({
  buttonLabel,
  className,
  imageMobile,
  imageTabletUp,
  title,
  content,
}) => {
  return (
    <div className={className}>
      <ImageContainer>
        {imageMobile && (
          <GatsbyImage alt="CTA" objectPosition="top center" image={imageMobile.childImageSharp?.gatsbyImageData} />
        )}
        {imageTabletUp && (
          <GatsbyImage
            alt="CTA"
            objectFit="cover"
            objectPosition="top center"
            image={imageTabletUp.childImageSharp?.gatsbyImageData}
          />
        )}
      </ImageContainer>
      <CTAContainer>
        <Title css={{ margin: "0 0 20px" }}>{title}</Title>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <ButtonAnchor href="mailto:info@tofinovr.com" target="_blank" rel="noopener noreferrer">
          <Button variant="transparentDark">{buttonLabel}</Button>
        </ButtonAnchor>
      </CTAContainer>
    </div>
  );
};

export default styled(RetreatsSection)`
  position: relative;
  display: grid;
  padding: 40px 0;
  grid-gap: 20px;
  max-width: 1130px;
  margin: 0 auto;

  ${media(Breakpoint.Tablet)} {
    padding: 100px 0 0;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    align-items: center;
  }

  ${media(Breakpoint.Desktop)} {
    grid-template-columns: 440px 555px;
    grid-gap: 135px;
  }
`;
